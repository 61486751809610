import React from "react"
import PropTypes from "prop-types"

class SalaireConjointe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      est_cadre_du_gouvernement_conjointe: this.props.estCadreDuGouvernementConjointe? 'Oui': 'Non',
      matricule_conjointe: this.props.matriculeConjointe || '',
      nombre_annee_service_conjoint: this.props.nombre_annee_service_conjoint || ''
    };

    this.CadreGouvernementChange = this.CadreGouvernementChange.bind(this)
    this.matriculeChange = this.matriculeChange.bind(this)
    this.nombreAnneeServiceConjointChange = this.nombreAnneeServiceConjointChange.bind(this)
  }

  CadreGouvernementChange(event) {
    this.setState({est_cadre_du_gouvernement_conjointe: event.target.value});
  }

  matriculeChange(event) {
    this.setState({matricule_conjointe: event.target.value});
  }

  nombreAnneeServiceConjointChange() {
    this.setState({nombre_annee_service_conjoint: event.target.value});
  }

  render () {
    const {est_cadre_du_gouvernement_conjointe, matricule_conjointe, nombre_annee_service_conjoint} = this.state
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email">Est Cadre de l'Administration publique ? *</label>
              <div className="col-sm-12">
                <div className="form-check form-check-inline">
                  <input id="oui" type="radio" name="demande_logement[est_cadre_du_gouvernement_conjointe]" value="Oui" onChange={this.CadreGouvernementChange} checked={est_cadre_du_gouvernement_conjointe==="Oui"} className="form-check-input" required={true} />
                  <label htmlFor="oui" className="form-check-label">Oui </label>
                </div>
                <div className="form-check form-check-inline">
                  <input id="non" type="radio" name="demande_logement[est_cadre_du_gouvernement_conjointe]" value="Non" onChange={this.CadreGouvernementChange} checked={est_cadre_du_gouvernement_conjointe==="Non"} className="form-check-input" required={true} />
                  <label htmlFor="non" className="form-check-label">Non </label>
                  <div className="invalid-feedback">Le champ est cadre de l'administration est obligatoire.</div>
                </div>
              </div>
            </div>
          </div>
          {
            est_cadre_du_gouvernement_conjointe === 'Oui' &&
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Matricule *</label>
                <input type="text" name="demande_logement[matricule_conjointe]" value={matricule_conjointe} pattern="[1-9]{1}[0-9]{5}[A-Za-z]{1}|[1-9]{1}[0-9]{4}[\/]{1}[G|g]" maxLength="7" className="form-control" required={true} onChange={this.matriculeChange} />
                <div className="invalid-feedback">Le matricule est obligatoire et doit avoir le format guineen.</div>
              </div>
            </div>
          }
        </div>
        {
          est_cadre_du_gouvernement_conjointe === 'Oui' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Nombre d'année de service restant conjoint.e </label>
                <input type="number" min={1} max={50} name="demande_logement[nombre_annee_service_conjoint]" value={nombre_annee_service_conjoint} className="form-control" onChange={this.nombreAnneeServiceConjointChange} />
                <div className="invalid-feedback">Le nombre d'année de service restant est obligatoire et doit être compris entre [1 - 50].</div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

SalaireConjointe.propTypes = {
  estCadreDuGouvernementConjointe: PropTypes.bool,
  matriculeConjointe: PropTypes.string
};
export default SalaireConjointe
